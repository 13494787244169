var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 800,
        visible: _vm.visible,
        okButtonProps: { props: { disabled: _vm.disableSubmit } },
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "航班号",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "ticketCode",
                          {
                            rules: [
                              { required: true, message: "请输入航班号!" },
                            ],
                          },
                        ],
                        expression:
                          "['ticketCode', {rules:[{ required: true,message: '请输入航班号!'}]}]",
                      },
                    ],
                    attrs: {
                      placeholder: "请输入航班号",
                      readOnly: _vm.disableSubmit,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "航班时间",
                    hasFeedback: "",
                  },
                },
                [
                  _c("j-date", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "tickectDate",
                          {
                            rules: [
                              { required: true, message: "请输入航班号!" },
                            ],
                          },
                        ],
                        expression:
                          "['tickectDate',{rules:[{ required: true,message: '请输入航班号!'}]}]",
                      },
                    ],
                    attrs: { "trigger-change": true },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单号码",
                    hidden: _vm.hiding,
                    hasFeedback: "",
                  },
                  model: {
                    value: this.orderId,
                    callback: function ($$v) {
                      _vm.$set(this, "orderId", $$v)
                    },
                    expression: "this.orderId",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["orderId", {}],
                        expression: "[ 'orderId', {}]",
                      },
                    ],
                    attrs: { disabled: "disabled" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "创建人",
                    hidden: _vm.hiding,
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["createBy", {}],
                        expression: "[ 'createBy', {}]",
                      },
                    ],
                    attrs: { readOnly: _vm.disableSubmit },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "创建时间",
                    hidden: _vm.hiding,
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["createTime", {}],
                        expression: "[ 'createTime', {}]",
                      },
                    ],
                    attrs: { readOnly: _vm.disableSubmit },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }